
import { Component, Mixins } from 'vue-property-decorator'
import Form from '@/components/forms/Form.vue'
import FormField from '@/components/forms/FormField.vue'
import InputText from '@/components/inputs/InputText.vue'
import InputTextarea from '@/components/inputs/InputTextarea.vue'
import ContactSubmission from 'tradingmate-modules/src/models/api/contact/ContactSubmission'
import { HttpError, Services } from 'tradingmate-modules/src/services'
import { FormPage } from '@/mixins'
import ContactDetail from '@/components/layout/ContactDetail.vue'
import ReCaptcha from '@/components/inputs/ReCaptcha.vue'
import VueRecaptcha from 'vue-recaptcha'

@Component({
  components: {
    VueRecaptcha,
    Form,
    FormField,
    ContactDetail,
    InputText,
    InputTextarea,
    ReCaptcha
  }
})
export default class Contact extends Mixins(FormPage) {
  private submitSuccessful = false
  private contactSubmission: ContactSubmission = {
    FullName: '',
    FromEmail: '',
    Message: '',
    ReCaptchaToken: ''
  }

  private isSubmitting = false
  private serverError = false;
  onFormSubmit (): void {
    this.isSubmitting = true
    this.submitSuccessful = false
    this.httpError = null
    this.serverError = false
    Services.API.Contact.SubmitContactForm(this.contactSubmission)
      .then((result) => {
        this.submitSuccessful = result
      }).catch((errors: HttpError) => {
        if (errors.Status === 500) {
          this.serverError = true
        } else {
          this.httpError = errors
        }
      }).finally(() => {
        this.isSubmitting = false
      })
  }
}
